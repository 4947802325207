@import '../../styles/mixins';
@import '../../styles/typography';
@import '../../styles/responsive';
@import '../../styles/spacing.scss';

.ctaBlock {
  text-align: center;
}

.ctaBlock--tan {
  background: $vroom-tan;
}

.ctaBlock--light_blue {
  background: $background-light-blue;
}

.ctaBlock__cta {
  @include cta(340px, 70px);
  padding-left: 10px;
  padding-right: 10px;
  border: 0;
}

.ctaBlock__sublink {
  font-family: var(--omnes-font);
  font-weight: 500;
  font-size: 18px;
  text-decoration: underline;
  display: block;
  margin-top: 20px;
  margin-bottom: 10px;
}

.ctaBlock--LargeSublink {
  .ctaBlock__sublink {
    @include cta(340px, 70px);
    border: solid 2px $vroom-teal;
    color: $vroom-teal;
    background: none;

    @include only-on(lgUp) {
      padding-left: 10px;
      padding-right: 10px;
    }

    &:hover {
      border: solid 2px $vroom-teal-hover;
    }
  }
}

.ctaBlock__appStoreLinks a {
  margin-left: 5px;
  margin-right: 5px;
}

.ctaBlock--extra-top-spacing,
.ctaBlock--extra-top-and-bottom-spacing {
  @extend .extra-top-spacing;
}

.ctaBlock--extra-bottom-spacing,
.ctaBlock--extra-top-and-bottom-spacing {
  @extend .extra-bottom-spacing;
}
