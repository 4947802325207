@import './responsive';


$spacing-0: 0px;
$spacing-1: 8px;
$spacing-2: 16px;
$spacing-3: 32px;
$spacing-4: 64px;
$spacing-5: 128px;

$spacing: (
  0: $spacing-0,
  1: $spacing-1,
  2: $spacing-2,
  3: $spacing-3,
  4: $spacing-4,
  5: $spacing-5,
);

.extra-top-spacing {
  padding-top: 64px !important;

  @include only-on(xl) {
    padding-top: 128px !important;
  }
}

.extra-bottom-spacing {
  padding-bottom: $spacing-4 !important;

  @include only-on(xl) {
    padding-bottom: $spacing-5 !important;
  }
}
